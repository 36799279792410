.slideshow-wrap {
    position: relative;
    margin: 0 7%;
    @media screen and (max-width: 1550px) {
        margin: 0;
    }
    .caption {
        position: absolute;
        top: 40%;
        left: 28%;
        width: 47%;
        transform: translateX(-50%);
        z-index: $z-index-slide-caption;
        h2 {
            font-weight: bold;
            font-size: $font-size-34;
            color: $color-white;
            text-transform: uppercase;
            padding-bottom: 25px;
        }
        p {
            font-size: $font-size-18;
            color: $color-white;
        }
        a.btn-orange {
            margin-top: 65px;
        }
        @media screen and (max-width: 1400px) {
            top: 35%;
            @media screen and (max-width: 1250px) {
                top: 25%;
                left: 35%;
                width: 60%;
                @media screen and (max-width: 1132px) {
                    top: 23%;
                    left: 38%;
                    width: 67%;
                    a.btn-orange {
                        margin-top: 35px;
                    }
                    @media screen and (max-width: $size-sm-max) {
                        top: 9%;
                        left: 46%;
                        width: 80%;
                        @media screen and (max-width: $size-xs-max) {
                            top: 17%;
                            left: 51%;
                            width: 90%;
                            a.btn-orange {
                                width: 175px;
                                height: 60px;
                            }
                            @media screen and (max-width: 375px) {
                                top: 13%;
                                @media screen and (max-width: 360px) {
                                    top: 11%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    div.carousel-wrapper {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 100%;
        z-index: 333;
        max-width: 600px;
        padding: 50px 0 50px 50px;
        background-color: $color-white;
        @media screen and (max-width: 1250px) {
            position: initial;
            margin: auto;
            @media screen and (max-width: $size-xs-max) {
                padding: 50px 5%;
            }
        }
    }
    .owl-nav {
        position: absolute;
        bottom: -50px;
        display: flex;
        div.owl-prev, div.owl-next {
            margin-right: 15px;
            transition: .3s;
            &:hover {
                opacity: .8;
                transition: .3s;
            }
        }
    }

    .slideshow {
      	width: 100%;
      	overflow: hidden;
      	position: relative;
      	.placeholder {
        		width: 100vw;
        		height: auto;
        		display: block;
            @media screen and (max-width: $size-slider-breakpoint){
                height: calc(100vw / 480 * 600);
            }
      	}
        &.mobile {
            display: none;
        }
      	.slide {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            width: 100vw;
            overflow: hidden;
            position: absolute;
            backface-visibility: hidden;
            transition: 1.5s;

            &_wrapper {
                position: relative;
                .layer-inner {
                    left: 0;
                    right: 0;
                    bottom: 20%;
                    padding: 20px;
                    position: absolute;
                    background: rgba(0,0,0, 0.5);

                    h2 {
                        font-weight: 900;
                        text-align: center;
                        font-size: $font-size-46;
                        color: $color-white;
                        @media screen and (max-width: $size-sm-max) {
                            font-size: 2rem;
                        }
                    }
                }
            }
        		img {
        			 width: 100vw!important;
        			 height: auto;
        			 display: block;
        			 position: relative;
        		}
            &.transiting {
                left: 0;
                right: 0;
                opacity: 1;
                transition: 1.5s;
        		}
        		&.active {
          			right: 0;
                left: 0;
          			z-index: 10;
                opacity: 1;
                transition: 1.5s;
        		}
        	}
        	@media screen and (max-width: 480px) {
                &.mobile {
                    display: block;
                }
                &.ecran {
                    display: none;
                }
            }
    }
}
