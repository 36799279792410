footer {
    min-height: $size-footer-height;
    @extend .bg-color-footer;
    @extend .flex;
    @extend .flex-wrap;
    @extend .items-center;

    .footer-wrapper {
        width: 100%;
        padding: 0 7%;
        @extend .flex;
        @extend .flex-wrap;
        @extend .items-center;
        @extend .justify-between;
        @extend .text-color-grey;
        @extend .font-footer;

        .section-logo {
            .copyright {
                font-weight: normal;
                font-size: $font-size-14;
                color: $color-1;
                opacity: 50%;
                text-transform: uppercase;
            }
        }
        /*.section-menu-footer {
            a.link {
                font-weight: bold;
                font-size: $font-size-14;
                color: $color-1;
                opacity: 50%;
                text-transform: uppercase;
                &:hover {
                    color: $color-2;
                }
            }
        }*/
        .section-logo2 {
            margin-right: 100px;
        }
        @media screen and (max-width: 1550px) {
            padding: 0 5%;
            @media screen and (max-width: $size-sm-max) {
                flex-direction: column-reverse;
                align-items: baseline;
                .section-logo2 {
                    margin: 30px 0px;
                }
                @media screen and (max-width: $size-xs-max) {
                    .section-logo2 {
                        .logo {
                            width: 40%;
                        }
                    }
                }   
            }   
        }
    }
    
        @media screen and (max-width: $size-sm-max) {
            height: 280px;
        }
    
}
