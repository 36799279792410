// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
//@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
@import '../vendors/owl-carousel2/owl.carousel';
// @import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}

a.btn-orange {
    background: $color-2;
    width: 235px;
    height: 80px;
    border-radius: 15px;
    transition: 0.3s;
    h5 {
        font-weight: bold;
        font-size: $font-size-18;
        color: $color-white;
        text-transform: uppercase;
    }
    &:hover {
        background: $color-1;
        transition: 0.3s;
    }
}

a.btn-blue {
    background: $color-1;
    width: 310px;
    height: 80px;
    border-radius: 15px;
    transition: 0.3s;
    h5 {
        font-weight: bold;
        font-size: $font-size-18;
        color: $color-white;
        text-transform: uppercase;
    }
    &:hover {
        background: $color-2;
        transition: 0.3s;
    }
}


/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

//@import 'sections/section-text-image';

@import 'sections/section-bursts';

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
//@import 'modules/module-slideshow';
@import 'modules/module-slider-light';
@import 'modules/module-header';
@import 'modules/module-soumission';



/*******************************************************************************
* ACCUEIL
*******************************************************************************/

#section-text-image {
    padding: 75px 0;
    margin: 75px 0;
    position: relative;
    .text-side {
        margin-left: 7%;
        width: 50%;
        padding-right: 50px;
        h3 {
            font-weight: bold;
            font-size: $font-size-34;
            color: $color-1;
            line-height: 1.3;
            padding-bottom: 35px;
        }
        .text {
            h4 {
                font-weight: 500;
                font-size: $font-size-24;
                color: $color-2;
            }
        }
        .left {
            width: 35%;
        }
        a.btn-orange {
            margin-top: 65px;
            width: 310px;
        }
    }
    &::before {
        content: '';
        background: #DD882E1A;
        z-index: -1;
        width: 80%;
        height: 95%;
        position: absolute;
        left: 0;
        top: 2%;
    }
    @media screen and (max-width: 1700px) {
        .text-side {
            h3 br {
                display: none;
            }
        }
        @media screen and (max-width: 1550px) {
            .text-side {
                margin-left: 5%;
                width: 60%;
                padding-right: 50px;
                h3 {
                    font-size: 1.5rem;
                }
                .left {
                    width: 40%;
                }
            }
            @media screen and (max-width: 1250px) {
                margin: 35px 0 75px 0;
                @media screen and (max-width: 1200px) {
                    flex-direction: column;
                    padding: 75px 0 50px 0;
                    .text-side {
                        margin: 0 0 75px 0;
                        width: 100%;
                        padding: 0 5%;
                    }
                    .image-side {
                        padding: 0 5%;
                    }
                    &::before {
                        width: 100%;
                        height: 70%;
                        top: 0;
                    }
                    @media screen and (max-width: $size-xs-max) {
                        .text-side .left {
                            width: 50%;
                        }
                    }
                }
            }
        }
    }
}

#section-services {
    padding: 50px 7% 50px 7%;
    h4 {
        font-weight: bold;
        font-size: $font-size-24;
        color: $color-1;
        text-transform: uppercase;
        padding-bottom: 25px; 
    }
    .bloc {
        .text {
            padding-top: 20px;
            h5 {
                font-weight: bold;
                font-size: $font-size-16;
                color: $color-3;
                line-height: 1.3;
            }
        }
    }
    a.btn-plus {
        background: $color-1;
        width: 265px;
        height: 50px;
        margin-top: 50px;
        transition: 0.3s;
        .text {
            h5 {
                font-weight: 500;
                font-size: $font-size-14;
                color: $color-white;
                text-transform: uppercase;
            }
        }
        &:hover {
            background: $color-2;
            transition: 0.3s;
        }
    }
    @media screen and (max-width: 1550px) {
        padding: 50px 5% 50px 5%;
        @media screen and (max-width: 1150px) {
            .section-images {
                flex-wrap: wrap;
                .bloc {
                    width: 50%;
                    padding: 15px;
                    margin: 0;
                }
            }
            @media screen and (max-width: $size-xs-max) {
                .section-images {
                    flex-wrap: wrap;
                    .bloc {
                        width: 100%;
                        padding: 0 0 35px 0;
                    }
                }
                a.btn-plus {
                    margin-top: 25px;
                }
            }
        }
    }
}

/*******************************************************************************
* PLOMBERIE
*******************************************************************************/

#section-header {
    padding: 50px 7%;
    background: #DD882E1A;
    position: relative;
    .text-side {
        width: 46%;
        padding-right: 100px;
        h3 {
            font-weight: bold;
            font-size: $font-size-34;
            color: $color-1;
            line-height: 1.3;
            padding-bottom: 35px;
        }
        p {
            font-weight: normal;
            font-size: $font-size-18;
            color: #202020;
        }
        a.btn-orange {
            margin-top: 65px;
            width: 390px;
        }
    }
    @media screen and (max-width: 1200px) {
        flex-direction: column;
        .text-side {
            width: 100%;
            padding: 0 0 50px 0;
        }
        @media screen and (max-width: $size-sm-max) {
            .text-side a.btn-orange {
                margin-top: 50px;
                width: 300px;
            }
            @media screen and (max-width: $size-xs-max) {
                .text-side a.btn-orange {
                    width: 250px;
                }
            }
        }
    }
}

#section-image-text {
    padding: 75px 0;
    margin: 75px 0;
    position: relative;
    .text-side {
        margin-right: 7%;
        width: 50%;
        padding-left: 5%;
        h3 {
            font-weight: bold;
            font-size: $font-size-34;
            color: $color-1;
            line-height: 1.3;
            padding-bottom: 15px;
        }
        p {
            font-weight: normal;
            font-size: $font-size-18;
            color: #202020;
        }
        .section-list {
            padding-top: 35px;
            .text {
                h4 {
                    font-weight: 500;
                    font-size: $font-size-24;
                    color: $color-2;
                }
            }
        }
        a.btn-blue {
            margin-top: 20px;
        }
    }
    &::before {
        content: '';
        background: #0F376D1A;
        z-index: -1;
        width: 80%;
        height: 95%;
        position: absolute;
        right: 0;
        top: 2%;
    }
    @media screen and (max-width: 1550px) {
        .text-side {
            h3 {
                font-size: 1.5rem;
            }  
        }
        @media screen and (max-width: 1250px) {
            margin: 35px 0 75px 0;
            flex-direction: column-reverse;
            padding: 75px 0 50px 0;
            .text-side {
                margin: 0 0 75px 0;
                width: 100%;
                padding: 0 5%;
            }
            .image-side {
                padding: 0 5%;
            }
            &::before {
                width: 100%;
                height: 75%;
                top: 0;
            }
            @media screen and (max-width: $size-xs-max) {
                margin: 50px 0;
                .text-side {
                    h3 br {
                        display: none;
                    }  
                }
                &::before {
                    height: 83%;
                }
            }   
        }
    }
}

#section-text-image.plomberie {
    .text-side {
        width: 47%;
        a.btn-orange {
            width: 435px;
        }
    }
    @media screen and (max-width: 1700px) {
        .text-side {
            h3 br {
                display: none;
            }
        }
        @media screen and (max-width: 1550px) {
            .text-side {
                margin-left: 5%;
                width: 60%;
                padding-right: 50px;
                h3 {
                    font-size: 1.5rem;
                }
                .left {
                    width: 40%;
                }
            }
            @media screen and (max-width: 1250px) {
                margin: 35px 0 75px 0;
                @media screen and (max-width: 1200px) {
                    flex-direction: column;
                    padding: 75px 0 50px 0;
                    .text-side {
                        margin: 0 0 75px 0;
                        width: 100%;
                        padding: 0 5%;
                        .left {
                            width: 30%;
                        }
                    }
                    .image-side {
                        padding: 0 5%;
                    }
                    &::before {
                        width: 100%;
                        height: 70%;
                        top: 0;
                    }
                    @media screen and (max-width: $size-xs-max) {
                        margin: 0;
                        .text-side {
                            a.btn-orange {
                                width: 300px;
                            }
                            .left {
                                width: 50%;
                            }
                        }
                    }
                }
            }
        }
    }
}

/*******************************************************************************
* DÉBOUCHAGE DE DRAIN
*******************************************************************************/

#section-image-text.drain {
    padding: 75px 0 75px 7%;
    .text-side {
        padding-right: 10%;
        a.btn-orange {
            width: 390px;
            margin-top: 50px;
        }
    }
    &::before {
        content: '';
        background: #DD882E1A;
        z-index: -1;
        width: 80%;
        height: 95%;
        position: absolute;
        right: 0;
        top: 2%;
    }
    @media screen and (max-width: 1550px) {
        padding: 75px 0 75px 5%;
        @media screen and (max-width: 1250px) {
            margin: 35px 0 75px 0;
            flex-direction: column-reverse;
            padding: 75px 0 50px 0;
            .text-side {
                margin: 0 0 75px 0;
                width: 100%;
                padding: 0 5%;
            }
            .image-side {
                padding: 0 5%;
            }
            &::before {
                width: 100%;
                height: 75%;
                top: 0;
            }
            @media screen and (max-width: $size-xs-max) {
                margin: 50px 0;
                .text-side a.btn-orange {
                    width: 265px;
                }
                &::before {
                    height: 83%;
                }
            }   
        }
    }
}

/*******************************************************************************
* INSPECTION PAR CAMÉRA
*******************************************************************************/





/*******************************************************************************
* COORDONNÉES
*******************************************************************************/

#section-coordonnees {
    padding: 0 7% 100px 7%;
    background: #CACBD53C;
    .form-info.flex {
        position: relative;
        margin-top: -250px;
    }
    .section-form {
        width: 65%;
        margin-right: 15px;
        background: $color-white;
        padding: 65px 100px 85px 100px;
        h3 {
            font-weight: bold;
            font-size: $font-size-30;
            color: $color-3;
            padding-bottom: 15px;
        }
        p {
            font-weight: normal;
            font-size: $font-size-18;
            color: #666F88;
            padding-bottom: 25px;
        }
        .form-group {
            margin-bottom: -15px;
        }
    }
    .section-right {
        width: 35%;
        padding: 65px 45px 35px 45px;
        background: $color-1;
        h2 {
            font-weight: 500;
            font-size: $font-size-30;
            color: $color-white;
            text-transform: uppercase;
        }
        h3 {
            font-weight: bold;
            font-size: $font-size-26;
            color: $color-white;
            text-transform: initial;
            padding-bottom: 5px;
        }
        h4 {
            font-weight: normal;
            font-size: $font-size-18;
            color: $color-white;
        }
        .section-info, .bloc-heures {
            .text {
                width: 100%;
            }
        }
        .jours-heures {
            .jour {
                width: 43%;
            }
            h4 {    
                font-weight: bold;
                font-size: $font-size-18;
                color: $color-white;
                line-height: 2;
            }
            h5 {
                font-weight: normal;
                font-size: $font-size-18;
                color: $color-white;
                line-height: 2;
            }
        }
    }
    @media screen and (max-width: 1550px) {
        padding: 0 5% 100px 5%;
        @media screen and (max-width: $size-md-max) {
            .form-info {
                flex-direction: column-reverse;
                margin-top: -150px !important;
                .section-right {
                    width: 60%;
                    margin: auto;
                }
                .section-form {
                    width: 85%;
                    margin: 35px auto 0 auto;
                }
            }
            @media screen and (max-width: 1024px) {
                .form-info {
                    .section-right {
                        width: 75%;
                        padding: 65px 45px;
                    }
                    .section-form {
                        width: 100%;
                    }
                }
                @media screen and (max-width: $size-sm-max) {
                    .form-info {
                        margin-top: -95px !important;
                        .section-right {
                            width: 90%;
                            .jours-heures .jour {
                                width: 32%;
                            }
                        }
                        .section-form {
                            padding: 65px 50px 50px 50px;
                        }
                    }
                    @media screen and (max-width: $size-xs-max) {
                        .form-info {
                            margin-top: -130px !important;
                            .section-right {
                                width: 100%;
                                padding: 50px 25px;
                                .jours-heures .jour {
                                    width: 32%;
                                }
                                h2 {
                                    text-align: center;
                                }
                            }
                            .section-form {
                                padding: 50px 25px;
                                .form-group.col-xs-12.col-sm-6.pl0 {
                                    padding: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

#section-map {
    padding: 75px 7%;
    h3 {
        font-weight: 600;
        font-size: $font-size-40;
        color: $color-1;
        padding-bottom: 65px;
    }
    @media screen and (max-width: 1550px) {
        padding: 75px 5%;
        @media screen and (max-width: 1024px) {
            #map-canvas {
                height: 500px !important;
            }
        }
    }
}

div#content {
    h3#firstHeading {
        font-size: $font-size-28;
        padding-bottom: 10px;
        color: $color-2;
    }
    p a {
        font-size: $font-size-20;
        color: $color-1;
    }
}







/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: $size-md-max) {
    

}

@media screen and (max-width: $size-sm-max) {
    

}

@media screen and (max-width: $size-xs-max) {
   

}
